class tecologyCountdownBlock {
	constructor(container) {
		this.container = container;

		this.date = container.getAttribute('data-date');
		this.timer = container.querySelectorAll(
			'.tecology-countdown-timer-container'
		);

		this.initTimer();

		this.daysItem = container.querySelectorAll(
			'.tecology-countdown-timer-day-container span'
		);

		this.hoursItem = container.querySelectorAll(
			'.tecology-countdown-timer-hour-container span'
		);

		this.minutesItem = container.querySelectorAll(
			'.tecology-countdown-timer-minute-container span'
		);

		this.secondsItem = container.querySelectorAll(
			'.tecology-countdown-timer-second-container span'
		);
	}

	initTimer() {
		var countDownDate = new Date(`${this.date}`).getTime();

		const tecologyCountdown = setInterval(() => {
			var now = new Date().getTime();

			var difference = countDownDate - now;

			if (Number.isNaN(difference)) {
				clearInterval(tecologyCountdown);
				return;
			}

			var days = Math.floor(difference / (1000 * 60 * 60 * 24));
			var hours = Math.floor(
				(difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			var minutes = Math.floor(
				(difference % (1000 * 60 * 60)) / (1000 * 60)
			);
			var seconds = Math.floor((difference % (1000 * 60)) / 1000);

			if (difference < 0) {
				clearInterval(tecologyCountdown);
				return;
			}

			this.daysItem.forEach((day, index) => {
				day.innerHTML = days;
			});

			this.hoursItem.forEach((hour, index) => {
				hour.innerHTML = hours;
			});

			this.minutesItem.forEach((minute, index) => {
				minute.innerHTML = minutes;
			});

			this.secondsItem.forEach((second, index) => {
				second.innerHTML = seconds;
			});

			this.setPlaceholder({
				days,
				hours,
				minutes,
				seconds,
			});
		}, 1000);
	}

	setPlaceholder({ days, hours, minutes, seconds }) {
		const placeholders = {
			'{{days}}': days.toString(),
			'{{hours}}': hours.toString(),
			'{{minutes}}': minutes.toString(),
			'{{seconds}}': seconds.toString(),
		};

		Object.keys(placeholders).forEach((handle) => {
			const value = placeholders[handle];
			const placeholderElements = this.container.querySelectorAll(
				`span[data-placeholder="${handle}"]`
			);

			placeholderElements.forEach((element) => {
				element.innerHTML = value;
			});
		});
	}
}

window.addEventListener('DOMContentLoaded', () => {
	const tecologyCountdownContainer = document.querySelectorAll(
		'.tecology-countdown-container'
	);

	tecologyCountdownContainer.forEach(
		(container) => new tecologyCountdownBlock(container)
	);
});
